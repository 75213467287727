ruliland@MacBook-Air-de-Raul src % cat components/results/partialResult.vue
<template>
  <v-card class="ml-10 mt-5 pt-10 pb-10 pl-5 resultCard" v-if="load">
    <v-row>
      <v-col cols="4" class="text-center" style="align-self: center">
        <h4 class="titleSection">{{ getTitle(index) }}</h4>
        <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            :value="partialScore"
            :color="color">
          <span class="score">{{ partialScore }}</span>
        </v-progress-circular>
      </v-col>
      <v-col cols="8">
        <p style="font-size: 14pt;padding: 0 15px 0 15px;font-weight: 400;">
          {{
            getText()
          }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "partialResult",
  props: ['index', 'color', 'score'],
  data() {
    return {
      partialScore: 0,
      load: false
    }
  },
  mounted() {
    this.getScore(this.index, this.score)
    this.load = true
  },
  methods: {

    getTitle(index) {
      switch (index) {
        case 0:
          return "Anàlisi del punt de partida";
        case 1:
          return "Nivell de planificació";
        case 2:
          return "Característiques del producte/servei";
        case 3:
          return "Accés a nous clients/mercats"
      }
    },
    getScore(index, score) {
      switch (index) {
        case 0:
          return this.partialScore = ((score * 20 / 16) * 100 / 20).toFixed(0)
        case 1:
          return this.partialScore = ((score * 20 / 12) * 100 / 20).toFixed(0)
        case 2:
          return this.partialScore = ((score * 20 / 20) * 100 / 20).toFixed(0)
        case 3:
          return this.partialScore = ((score * 20 / 10) * 100 / 20).toFixed(0)
      }
    },
    getText() {
      switch (this.index) {
        case 0:
          if (this.score >= 20)
            return "La seva empresa té certes deficiències que cal corregir de forma ineludible previament a l'inici del procés d'internacionalització."
          else if (this.score >= 10 && this.score < 20)
            return "La seva empresa es troba en un bon punt de partida per iniciar el seu procés d'internacionalització. No obstant, és important corregir certs aspectes de rellevància abans de l'inici."
          else if (this.score < 10)
            return "La seva empresa té certes deficiències que cal corregir de forma ineludible previament a l'inici del procés d'internacionalització."
          break;
        case 1:
          if (this.score >= 20)
            return "El nivell de planificació de la seva empresa pel que fa a recursos humans, motivació, implicació i recursos es l'adeqüat per afrontar el seu procés d'internacionalització."
          else if (this.score >= 10 && this.score < 20)
            return "El nivell de planificació de la seva empresa pel que fa a recursos humans, motivació, implicació i recursos es correcte per afrontar el seu procés d'internacionalització, tot i que podria millorar en certs aspectes."
          else if (this.score < 10)
            return "És necessari que el nivell de planificació de la seva empresa pel que fa a recursos humans, motivació, implicació i recursos sigui més professional per afrontar, amb garanties, el seu procés d'internacionalització."
          break;
        case 2:
          if (this.score >= 20)
            return "El potencial d'exportació del seu producte / servei és elevat"
          else if (this.score >= 10 && this.score < 20)
            return "Cal treballar en els atractius del producte / servei per pder ser més competitius en l'àmbit internacional."
          else if (this.score < 10)
            return "El potencial d'exportació del seu producte / servei és baix.  Cal analitzr en profunditat si és factible millorar el seu potencial."
          break;
        case 3:
          if (this.score >= 20)
            return "L'empresa compta amb una estratègia comercial a la que només cal incorporar la vessant internacional."
          else if (this.score >= 10 && this.score < 20)
            return "L'empresa ha de millorar la seva manera de comercialitzar els seus productes / serveis"
          else if (this.score < 10)
            return "Resulta imprescindible que l'empresa implanti una estratègia de comercialització."
          break;
      }
    }
  }
}
</script>

<style scoped>
.titleSection {
  text-align: left;
  margin-bottom: 1em;
  margin-left: 1em;
  font-size: 11pt;
  font-weight: 400;
}

.score {
  font-size: 25pt;
}
.resultCard{
  background: white;
}
</style>