<template>
  <v-card light class="mx-auto pt-5 pa-5" max-width="100%" :loading="!loaded">
    <v-card-title v-if="loaded" style="padding: 5px 0px 15px 0px !important; display: inline-block">
      <h3 style="color: #ba0c2f" >
        Cliente: {{ client.name }}
        <v-icon class="ml-2" color="#ba0c2f !important" small v-on:click="getData">mdi-reload</v-icon>
        <v-btn dark class="ml-5" color="#ba0c2f !important" @click="setRevised" v-if="client.revised == 0">Marcar com a revisat</v-btn>
        <a :href="'https://diagnosiexport.cambralleida.org/api/resumeDoc.php?tok='+client.token" target="_blank">
          <v-btn dark class="ml-5" color="#ba0c2f !important"  >Descarregar informe PDF</v-btn>
        </a>
      </h3>
    </v-card-title>
    <v-alert
        v-if="errorInCharge"
        dense
        outlined
        type="error">
      Hi ha hagut un error en obtenir les dades, intenta-ho de nou més tard
    </v-alert>
    <v-tabs
        color="#ba0c2f"
        v-model="tab">
      <v-tab
          v-for="item in items"
          :key="item">
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" light>
      <v-tab-item>
        <v-row class="ma-5">
          <v-col cols="12" md="3">
            <v-text-field v-model="client.name" color="#d52787" label="Nom i cognoms / Raó social*" disabled/>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="client.nif" color="#d52787" label="N.I.F." disabled/>
          </v-col>
          <v-col cols="12" md="2">
            <a :href="'tel:'+client.phone" target="_blank">
            <v-text-field v-model="client.phone" color="#d52787" label="Telèfon" disabled/>
            </a>
          </v-col>
          <v-col cols="12" md="3">
            <a :href="'mailto:'+client.email" target="_blank">
            <v-text-field v-model="client.email" color="#d52787" label="E-Mail" disabled/>
            </a>
          </v-col>
          <v-col cols="12" md="2">
            <a :href="client.web" target="_blank">
            <v-text-field v-model="client.web" color="#d52787" label="Web" disabled/></a>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field :value="'persona '+client.type" color="#d52787" label="Tipus de identitat" disabled/>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="client.sector" color="#d52787" label="Sector" disabled/>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="client.subsector" color="#d52787" label="Subsector" disabled/>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="client.year" color="#d52787" label="Any de creació" disabled/>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field v-model="client.town" color="#d52787" label="Provincia" disabled/>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="ma-5">
          <v-col cols="12" v-if="loaded" >
            <global-result class="mt-5" :partial-array="partialScore"/>
            <partial-result v-for="(pscore,index) in partialScore" v-bind:key="index" :index="index"
                            itle="Anàlisi del punt de partida"
                            :color="getColor(index)" :score="pscore.points"/>

          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row class="ma-5" v-if="loaded">
          <responses :responses="responses" :sector="client.sector" :subsector="client.subsector"/>
        </v-row>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</template>

<script>
import globalResult from "../components/client/global";
import partialResult from "../components/client/partial";
import Responses from "../components/client/responses";
export default {
  components: {Responses, globalResult, partialResult},
  name: "Client",
  data(){
    return{
      tab:0,
      client:null,
      loaded:false,
      errorInCharge:false,
      items:['Informació','Resultats de l\'autodiagnosi','Respostes individuals'],
      partialScore:[],
      responses:[]
    }
  },
  mounted() {
  this.getData()
  },
  methods:{
    setRevised(){
      let formData = new FormData();
      formData.set('clientid', this.client.idclient)
      let url = this.env_url + 'setrevised.php'
      this.axios.post(url, formData).then(() => {
        this.getData()
      });
    },
    getData(){
      this.errorInCharge = false
      let url = this.env_url + "/getclient.php?cid="+this.$route.params.clientid;
      let url2 = this.env_url +"/getclientresponses.php?cid="+this.$route.params.clientid;
      this.axios.get(url).then(response => {
        this.client = response.data.client;
        this.partialScore.push({points: response.data.client.partialScore1})
        this.partialScore.push({points: response.data.client.partialScore2})
        this.partialScore.push({points: response.data.client.partialScore3})
        this.partialScore.push({points: response.data.client.partialScore4})
        this.axios.get(url2).then(response2 =>{
          this.responses = response2.data.responses;
          this.loaded = true;
        }).catch(()=>{
          this.errorInCharge = true
        })
      }).catch(()=>{
        this.errorInCharge = true
      })
    },
    getColor(index) {
      switch (index) {
        case 0:
          return 'blue';
        case 1:
          return 'orange';
        case 2:
          return 'green';
        case 3:
          return 'pink';
      }
    }
  }
}
</script>

<style scoped>

</style>