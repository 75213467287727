<template>
  <v-container>
    <v-row justify="space-around" align="center" class="align-content-center">
      <v-col class="10">
        <v-card class="mx-auto pl-10 pr-10">
          <v-card-title>
            <h3 class="titleCard">DADES DE L'EMPRESA</h3>
          </v-card-title>
          <v-col cols="2" class="mt-n4 ml-n2 mb-5">
            <v-divider></v-divider>
          </v-col>
          <v-row>
            <v-col cols="6" v-for="question in dadesq" v-bind:key="question.id">
              <transition name="fade">
                <question :question="question.question" :type="question.type" :sector="sector" :subsector="subsector"
                          :responses="question.responses" :answers="responses" :id="question.id"/>
              </transition>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      </v-row><v-row>
      <v-col class="10">
      <v-card class="mx-auto pl-10 pr-10">
        <v-card-title>
          <h3 class="titleCard">ANÀLISI DEL PUNT DE PARTIDA</h3>
        </v-card-title>
        <v-col cols="6" class="mt-n4 ml-n2 mb-5">
          <v-divider></v-divider>
        </v-col>
        <v-row>
          <v-col cols="6" v-for="question in analisisq" v-bind:key="question.id">
            <question :question="question.question" :type="question.type"
                      :responses="question.responses" :answers="responses" :id="question.id"/>
          </v-col>
        </v-row>
      </v-card>
      </v-col></v-row><v-row>
      <v-col class="10">
      <v-card class="mx-auto pl-10 pr-10">
        <v-card-title>
          <h3 class="titleCard">NIVELL DE PLANIFICACIÓ</h3>
        </v-card-title>
        <v-col cols="5" class="mt-n4 ml-n2 mb-5">
          <v-divider></v-divider>
        </v-col>
        <v-row>
          <v-col cols="6" v-for="question in planificacioq" v-bind:key="question.id">
            <question :question="question.question" :type="question.type"
                      :responses="question.responses" :answers="responses" :id="question.id"/>
          </v-col>
        </v-row>
      </v-card>
      </v-col></v-row><v-row>
      <v-col class="10">
      <v-card class="mx-auto pl-10 pr-10">
        <v-card-title>
          <h3 class="titleCard">CARACTERÍSTIQUES DEL PRODUCTE / SERVEI</h3>
        </v-card-title>
        <v-col cols="5" class="mt-n4 ml-n2 mb-5">
          <v-divider></v-divider>
        </v-col>
        <v-row>
          <v-col cols="6" v-for="question in caracteristicasq" v-bind:key="question.id">
            <question :question="question.question" :type="question.type"
                      :responses="question.responses" :answers="responses" :id="question.id"/>
          </v-col>
        </v-row>
      </v-card>
      </v-col></v-row><v-row>
      <v-col class="10">
      <v-card class="mx-auto pl-10 pr-10">
        <v-card-title>
          <h3 class="titleCard">ACCÉS A NOUS CLIENTS / MERCATS</h3>
        </v-card-title>
        <v-col cols="5" class="mt-n4 ml-n2 mb-5">
          <v-divider></v-divider>
        </v-col>
        <v-row>
          <v-col cols="6" v-for="question in nousclientsq" v-bind:key="question.id">
            <question :question="question.question" :type="question.type"
                      :responses="question.responses" :answers="responses" :id="question.id"/>
          </v-col>
        </v-row>
      </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Question from "./question";

import {dadesempresa} from "../questions/dadesempresa";
import {analisisquestions} from "../questions/analisispartida";
import {planificacioquerys} from "../questions/planificacio";
import {caracteristicasproducto} from "../questions/caracteristicasproducto";
import {nousclients} from "../questions/nousclients";
export default {
  name: "responses",
  components: {Question},
  props:['responses','sector','subsector'],
  data(){
    return{
      analisisq: analisisquestions,
      dadesq: dadesempresa,
      planificacioq: planificacioquerys,
      caracteristicasq: caracteristicasproducto,
      nousclientsq: nousclients,
      clients:[],
    }
  }
}
</script>

<style scoped>

</style>