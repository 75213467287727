<template>
  <section v-if="loaded">
    <h3 ref='focusMe'>{{ question }}</h3>
    <div v-if="type==='check'">
      <div v-for="(response,index) in responses"  v-bind:key="response.title">
      <label  v-if="compare(index)" :for="index">{{response.title}}</label>
      </div>
    </div>
    <label v-else-if="id === 1">{{sector}}</label>
    <label v-else-if="id === 2">{{subsector}}</label>
  </section>
</template>

<script>
export default {
  name: "question",
  props: ['question', 'responses','answers','sector','subsector', 'id', 'part', 'type', 'updateScore', 'selectedSector'],
  mounted() {
    if (this.type === 'select') {
      if (this.id === 1)
        this.responses.forEach(response => {
          this.selectOptions.push({value: response.title, text: response.title})
        })
      if (this.id === 2) {
        this.responses.forEach(response => {
          if(response.key === this.selectedSector){
            response.value.forEach(resps=>{
              this.selectOptions.push({value: resps.title, text: resps.title})
            })
          }
        })
      }
    }
    const ida = this.id-1
    this.answersData = this.answers[ida].answer;
    this.loaded = true;
  },
  data() {
    return {
      radioGroup: null,
      selectOptions: [],
      selResp: 0,
      selResp2:0,
      answersData:[],
      loaded: false
    }
  },
  methods:{
    compare(index){
      return index == this.answersData
    }
  }
}
</script>

<style scoped>

</style>